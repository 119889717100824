/*------------------------------------------------------------------------------------------------------------------------
 @package: typocracy (site)

 @author: Bryan V
 @www: typocracy.net
 @github: bryanjvolz

 @twitter: typocracy

 @copyright: COPYRIGHT 2019 Typocracy
 =============================================================================
 Filename: style.scss
 =============================================================================
 --------------------------------------------------------------------------------------------------------------------- */

@import 'partials/normalize';
@import 'partials/icomoon/icomoon';

@import 'partials/base/vars';
@import 'partials/base/functions';
@import 'partials/base/base';
@import 'partials/base/fonts';
@import 'partials/base/animation';

@import 'partials/modules/homepage';
@import 'partials/modules/resume';
